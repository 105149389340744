<template>
  <div class="search">
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.0621 11.8375C13.161 10.6856 13.8357 9.12542 13.8357 7.40771C13.8357 3.86263 10.9618 0.98877 7.41674 0.98877C3.87166 0.98877 0.997803 3.86263 0.997803 7.40771C0.997803 10.9528 3.87166 13.8266 7.41674 13.8266C9.2441 13.8266 10.8931 13.063 12.0621 11.8375ZM12.0621 11.8375L16.775 16.5504" stroke="#E0E0E0" stroke-width="1.36259" stroke-linecap="round"/>
    </svg>
    <input class="search__input" v-model="getSearch" placeholder="Поиск">
  </div>
</template>

<script>
export default {
  name: "SidebarSearch",
  props: ['search'],
  computed: {
    getSearch: {
      get () { return this.search },
      set (value) { this.$emit('updateSearch', value) },
    },
  },

}
</script>

<style scoped lang="scss">
.search{
  display: flex;
  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  border-radius: 4px;
  align-items: center;
  width: 268px;
  margin-top: 12px;
  margin-left: 10px;
  margin-bottom: 12px;

  svg{
    margin-left: 10px;
  }

  &__input{
    border: 0;
    outline: 0;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: black;
    height: 36px;
    width: calc(100% - 50px);
    margin-left: 10px;
    background: transparent;

    &::placeholder{

      color: #E0E0E0;
    }
  }
}
</style>