import axios from "axios";

export default {
    httpClient: null,
    getHttpClient() {
        this.httpClient = axios.create({
            baseURL: 'https://api.esova.ru'
            // baseURL: 'https://ntest-api.esova.ru'
            // baseURL: 'http://localhost:8085'
        })
        this.httpClient.interceptors.request.use((config) => {
            if (config.headers.common.Authorization == null) {
                config.headers.common.Authorization = `Bearer ${localStorage.getItem('accessToken')}`
            }

            if (config.headers['Content-Type'] == null) {
                config.headers.common['Content-Type'] = 'application/json'
            }
            return config
        }, function (error) {
            return Promise.reject(error)
        })
        this.httpClient.interceptors.response.use((response) => {
            return response
        }, (error) => {
            if (error.response == null) throw error
            if (error.response.status === 401) {
                const refreshToken = localStorage.getItem('refreshToken')
                return this.httpClient.put(`auth/${refreshToken}`, null).then((response) => {
                    if (response == null) {
                        // localStorage.clear();
                        // document.location.href = 'auth'
                    }

                    if (response.status === 400) {
                        // localStorage.clear();
                        document.location.href = '/auth'
                    }
                    const token = response.data
                    localStorage.setItem('accessToken', token.accessToken)
                    localStorage.setItem('refreshToken', token.refreshToken)

                    error.config.headers.Authorization = `Bearer ${token.accessToken}`
                    return this.httpClient.request(error.config)
                })
            } else {
                return error.response
            }
        })

        return this.httpClient

    }
}
