<template>
  <div class="sidebar">
    <div class="sidebar__content">
      <sidebar-search :search="search" @updateSearch="updateSearch"></sidebar-search>
      <sidebar-list :search="search" :school="school"></sidebar-list>
    </div>
  </div>
</template>

<script>
import SidebarSearch from "../LeftBar/SidebarSearch";
import SidebarList from "../LeftBar/SidebarList";
export default {
  name: "LeftBar",
  components: {SidebarList, SidebarSearch},
  data() {
    return {
      school: [],
      search: ''
    }
  },
  methods: {
    async loadSchool() {
      const resp = await this.$axios.get('educations')
      if (resp.status === 200) {
        this.school = resp.data
      }
    },
    updateSearch(val) {
      this.search = val
    }
  },
  mounted() {
    this.loadSchool()
  }
}
</script>

<style scoped lang="scss">
  .sidebar {
    position: absolute;
    top: 96px;
    width: 288px;
    height: 576px;
    left: 12px;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    overflow: hidden;
    bottom: 0;
    transform: translateX(0px);
    transition: transform .5s;
    &__title {
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      padding: 10px;
      border-bottom: 1px solid #fff;
      a {
        color: #fff;
        text-decoration: none;
      }
    }
    &__content {
      background-color: #fff;
      height: 100%;
      max-height: 100%;
      overflow-y: auto;
      margin-bottom: 70px;
    }

    &__users {
      position: absolute;
      bottom: 15px;
      left: 0;
      right: 0;
      padding: 0 10px;
      a {
        background: rgba(#FFFFFF, .5);
        mix-blend-mode: normal;
        border: 1px solid #979797;
        border-radius: 8px;
        display: block;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        /* identical to box height */
        text-transform: uppercase;
        text-decoration: none;
        height: 41px;
        line-height: 41px;
        letter-spacing: -0.0814546px;
        color: #343A40;
        padding: 0 16px;
      }
    }


    @include media(1400){
      transform: translateX(-1000px);
    }
  }
</style>