<template>
  <div class="stat">
    <div class="stat__head" :style="{background: stat.config.headerColor}" v-if="stat.config.showHeader">
      <span class="stat__count">{{ stat.config.headerCount }}</span>
      <span class="stat__text">{{ stat.config.headerText }}</span>
    </div>
    <div class="stat__head stat__head_small" :style="{background: stat.config.headerColor}" v-else></div>
    <div class="stat__content">
      <div class="stat__info">
        <div class="stat__item" v-if="!$route.query.school">
          <div class="stat__counter">
            {{ educations.length }}
          </div>
          <div class="stat__name">Учереждений</div>
        </div>
        <div class="stat__item" v-else>
          <div class="stat__counter">
            {{ classes.length }}
          </div>
          <div class="stat__name">Классов</div>
        </div>
        <div class="stat__item">
          <div class="stat__counter">
            {{ cameras }}
          </div>
          <div class="stat__name">Камер</div>
        </div>
      </div>
      <div class="stat__select">
        <div class="stat__select">
          <select name="" @change="changeSchool">
            <option :value="undefined" :selected="$route.query.school === undefined">Все учреждения</option>
            <option v-for="val in educations" :key="val.accountId" :value="val.accountId" :selected="$route.query.school === val.accountId.toString()">{{val.name}}</option>
          </select>
        </div>
        <div class="stat__select" v-if="$route.query.school">
          <select name="" @change="changeClass">
            <option :value="undefined" :selected="$route.query.class === undefined">Все классы</option>
            <option v-for="val in classes" :key="val.id" :value="val.id" :selected="$route.query.school === val.id.toString()">{{val.fullName}}</option>

          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatItemAll",
  props: ['cameras','edus'],
  data() {
    return {
      education: [],
      classes: [],
      stat: {
        config: {
          showHeader: false,
          headerColor: '#8A8A8A',
          showGraph: false,
          openAll: true,
        }
      }
    }
  },
  methods: {
    changeSchool(e) {
      this.$router.replace({query: {class: undefined, school: e.target.value}})
    },
    changeClass(e) {
      this.$router.replace({query: {class: e.target.value, school: this.$route.query.school}})
    },
    async loadClass() {
      this.classes = []
      if (this.$route.query.school) {
        const resp = await this.$axios.get(`/school/${this.$route.query.school}/class`)
        this.classes = resp.data
      }
    }
  },
  computed: {
    getSchool() {
      return this.$route.query.school
    },
    educations(){
      return (this.edus == null) ? this.education : this.edus
    }
  },
  watch: {
    getSchool() {
      this.loadClass()
    }
  },
  async mounted() {
    if (this.edus == null){
      const resp = await this.$axios.get('educations')
      if (resp.status === 200) {
        this.education = resp.data
      }
    } else {
      this.education = this.edus
    }
    await this.loadClass()
  }
}
</script>

<style scoped lang="scss">
.stat {
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  margin: 0 10px;
  width: 100%;
  margin-bottom: 20px;
  &_medium {
    max-height: 200px;
  }

  &__head {
    display: flex;
    align-items: center;
    height: 33px;

    &_small {
      height: 6px;
    }
  }

  &__count {
    padding-right: 25px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #3B3F48;
  }

  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #3B3F48;
  }

  &__content {
    padding: 0px 0 16px;
    background-color: #fff;
  }

  &__graph {
    height: 150px;
    text-align: center;
  }

  &__item {
    display: flex;
    max-width: 170px;
    align-items: center;
    margin-bottom: 8px;

  }

  &__counter {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #3B3F48;
    line-height: 16px;

    width: 48px;
    text-align: right;
  }

  &__name {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #3B3F48;
    margin-left: 12px;
  }

  &__info {
    padding-top: 12px;
    margin-left: 18px;
    display: flex;
    flex-direction: column;
  }

  &__circe {
    width: 9px;
    height: 9px;
    border-radius: 10em;
  }

  &__select {
    padding-top: 10px;

    width: 201px;


    select {
      margin-left: 25px;

      width: 201px;
      height: 48px !important;
      padding-left: 14px;
      font-weight: 400;
      font-size: 14px;
      letter-spacing: 1px;
      line-height: 16px;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
      background-repeat: no-repeat !important;
      background-position-x: 95% !important;
      background-position-y: 10px !important;

      background: #FFFFFF;
      border: 1px solid #E2E5E9;
      border-radius: 8px;
    }
  }
}
</style>