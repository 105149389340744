import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    schools: []
  },
  mutations: {
    SET_SCHOOLS: (state,schools) => {
      state.schools = schools
    }
  },
  actions: {

  },
  modules: {
  }
})
