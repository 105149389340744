<template>
  <div class="counter__row">
    <div class="counter">
      <div class="counter__name">{{counter.name}}</div>
      <div class="counter__address" v-if="counter.address">{{counter.address}}</div>
    </div>
    <div class="counter">
      <div class="counter__item">
        <span class="counter__item__percent counter__item__percent_green">{{getAllCountsLeft > 0 ? getPercentToData(counter.statistic.payAccounts * 100 / getAllCountsLeft) : 0}}%</span>
        <span class="counter__text">{{counter.statistic.payAccounts}} платящих</span>
      </div>
      <div class="counter__item">
        <span class="counter__item__percent counter__item__percent_red">{{getAllCountsLeft > 0 ? getPercentToData(counter.statistic.unPayAccounts * 100 / getAllCountsLeft) : 0}}%</span>
        <span class="counter__text">{{counter.statistic.unPayAccounts}} неплатящих</span>
      </div>
      <div class="counter__item">
        <span class="counter__item__percent counter__item__percent_orange">{{getAllCountsLeft > 0 ? getPercentToData(counter.statistic.demoCount * 100 / getAllCountsLeft) : 0}}%</span>
        <span class="counter__text">{{counter.statistic.demoCount}} демо</span>
      </div>
    </div>
    <div class="counter">
      <div class="counter__item">
        <span class="counter__item__percent counter__item__percent_left counter__item__percent_grey">{{counter.statistic.parentsCount > 0 ? getPercentToData(counter.statistic.unavailableKeys * 100 / counter.statistic.parentsCount) : 0}}%</span>
        <span class="counter__count">{{counter.statistic.unavailableKeys}}</span>
        <span class="counter__text">регистраций</span>
      </div>
      <div class="counter__item">
        <span class="counter__item__percent counter__item__percent_left counter__item__percent_grey">{{counter.statistic.parentsCount > 0 ? getPercentToData(counter.statistic.availableKeys * 100 / counter.statistic.parentsCount) : 0}}%</span>
        <span class="counter__count">{{counter.statistic.availableKeys > 0 ? counter.statistic.availableKeys : 0}}</span>
        <span class="counter__text">незарег.</span>
      </div>
      <div class="counter__item">
        <span class="counter__item__percent counter__item__percent_left counter__item__percent_grey"></span>
        <span class="counter__count">{{counter.statistic.parentsCount}}</span>
        <span class="counter__text">родителей</span>
      </div>
    </div>
    <div class="counter counter_small">
      <div class="counter__item counter__item_small">
        <span style="color: #3B3F48;padding-right: 3px">{{all.parents > 0 ? getPercentToData(counter.statistic.parentsCount * 100 / all.parents) : 0}}%</span>
        <span class="counter__text">({{counter.statistic.parentsCount}} чел.)</span>
      </div>
    </div>
    <div class="counter counter_small">
      <div class="counter__item counter__item_small">
        <span style="color: #3B3F48;padding-right: 3px">{{ getPercentToData(counter.statistic.unavailableKeys * 100 / all.account)}}%</span>
        <span class="counter__text">({{counter.statistic.unavailableKeys}} чел.)</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CounterItem",
  props: ['counter', 'all'],
  computed: {
    getAllCountsLeft() {
      const stat = this.counter.statistic
      return stat.payAccounts + stat.unPayAccounts + stat.demoCount
    }
  },
  methods: {
    getPercentToData(data) {
      if (data % 1 === 0) {
        return data
      }
      else {
        return data.toFixed(0)
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .counter {
    padding: 18px 20px 12px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.044799);
    width: 25%;
    &_small {
      width: 12.5%;
      padding-left: 5px;
      padding-right: 5px;

    }
    &__row {
      display: flex;
      justify-content: space-between;
    }
    &__item {

      &__percent{
        width: 38px !important;
        text-align: right;
        display: inline-block;

        &_left{
          text-align: left !important;
        }

        &_grey{
          color: #3B3F48;
        }


        &_green{
          color: #7ED321
        }

        &_red{
          color: #D0021B
        }

        &_orange{
          color: #F5A623
        }
      }

      &_small{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
      }
      span {
        &:first-child {
          font-weight: bold;
          width: 45px;
          text-align: right;
          padding-right: 8px;
        }
        &:last-child {
          width: 70%;
        }
        font-size: 12px;
        //padding-right: 1px;
        font-weight: 400;
      }
    }
    &_small {
      background-color: rgba(0, 0, 0, 0.0292832);
      .counter__item {
        flex-direction: column;
        justify-content: flex-start;
        span {
          width: 100%;
          text-align: center;
        }
      }
    }
    &__name {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #3B3F48;
      line-height: 1.2;
    }
    &__address {
      font-style: normal;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
      color: #3B3F48;
      opacity: .6;
    }

    &__text {
      font-style: normal;
      //font-weight: bold;
      font-size: 12px;
      color: #3B3F48;
      font-weight: normal;
    }

    &__count{
      font-style: normal;
      font-weight: bold !important;
      font-size: 12px;
      color: #3B3F48;
      width: 32px !important;
      padding-right: 5px;
      display: inline-block;
    }
  }
</style>