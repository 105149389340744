<template>
  <div class="stat">
    <div class="stat__head" :style="{background: stat.config.headerColor}" v-if="stat.config.showHeader">
      <span class="stat__count">{{ stat.config.headerCount }}</span>
      <span class="stat__text">{{ stat.config.headerText }}</span>
    </div>
    <div class="stat__head stat__head_small" :style="{background: stat.config.headerColor}" v-else></div>
    <div class="stat__content">
      <div class="stat__graph" v-if="stat.config.showGraph">
        <div ref="graph"></div>
      </div>
      <div class="stat__info"
        :class="{'stat__info_sm' : size === 'sm'}">
        <div class="stat__item" v-for="(item, index) in stat.data" :key="index">
          <div class="stat__counter">
            {{ item.count }}
          </div>
          <div class="stat__statistic" :class="['stat__statistic_'+size]">
            <div v-if="size !== 'sm'" class="stat__circe" :style="{background: item.color}"></div>
            <div class="stat__name">{{ item.text }}</div>
          </div>

        </div>
      </div>
      <div class="stat__select" v-if="stat.config.openAll">
        <select name="" id="" v-model="activeField">
          <option value="" selected>Все учреждения</option>
          <option :value="item.accountId" v-for="(item, index) in fields" :key="index">{{ item.name }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import _ from 'lodash'

export default {
  name: "StatItem",
  props: ['stat','size'],
  data() {
    return {
      width: 230,
      height: 190,
      margin: 20,
    }
  },
  methods: {
    getPercentToData(data) {
      if (data % 1 === 0) {
        return data
      }
      else {
        return data.toFixed(2)
      }
    },
    generateGraph() {
      this.radius = Math.min(this.width, this.height) / 2 - this.margin
      this.svg = d3.select(this.$refs.graph)
          .append("svg")
          .attr("width", this.width)
          .attr("height", this.height)
          .append("g")
          .attr("transform", `translate(${this.width / 2},${this.height / 2})`);
      this.color = d3.scaleOrdinal().range(d3.schemeDark2);
      this.pie = d3.pie()
          .sort(null) // Do not sort group by size
          .value(d => {
            return d.count
          })

      this.dataPie = this.pie(this.stat.data)
      this.arc = d3.arc()
          .innerRadius(this.radius * 0.2)
          .outerRadius(this.radius * 0.7)

      this.outerArc = d3.arc()
          .innerRadius(this.radius * 0.9)
          .outerRadius(this.radius * 0.9)
      this.svg
          .selectAll('allSlices')
          .data(this.dataPie)
          .join('path')
          .attr('d', this.arc)
          .attr('fill', d => {
            return d.data.color
          })
          .attr("stroke", "black")
          .style("stroke-width", "0px")
          .style("opacity", 1)
      this.svg
          .selectAll('allPolylines')
          .data(this.dataPie)
          .join('polyline')
          .attr("stroke", 'black')
          .style("fill", "none")
          .attr("stroke-width", 1)
          .style("opacity", .2)
          .attr('points', (d) => {
            if (!this.positions) {
              this.positions = []
            }
            let posA = this.arc.centroid(d) // line insertion in the slice
            let posB = this.outerArc.centroid(d) // line break: we use the other arc generator that has been built only for that
            let posC = this.outerArc.centroid(d); // Label position = almost the same as posB
            const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2 // we need the angle to see if the X position will be at the extreme right or extreme left
            let angle = midangle < Math.PI ? 1 : -1
            const findAngle = _.find(this.positions, d => {
              let angleStart = Number(d.angle - 0.2)
              let angleEnd = Number(d.angle + 0.2)
              return angleStart >= Number(midangle.toFixed(2)) || Number(midangle.toFixed(2)) <= angleEnd
            })
            if (findAngle) {
              angle = findAngle === -1 ? 1 : -1
            }
            this.positions.push({angle: midangle, arr: angle, name: this.stat.config.headerText})
            posB[1] = posB[1] + 4
            posC[1] = posC[1] + 4
            posC[0] = this.radius * 0.7 *  angle; // multiply by 1 or -1 to put it on the right or on the left
            return [posA, posB, posC]
          })
      this.svg
          .selectAll('allLabels')
          .data(this.dataPie)
          .join('text')
          .text(d => this.getPercentToData(d.data.count * 100 / this.stat.config.all  ) + '%')
          .style('color', d => d.data.color)
          .attr("fill", d => d.data.color)
          .attr('transform', (d, i) => {
            const pos = this.outerArc.centroid(d);
            //const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
            pos[0] = this.radius * 0.8 * this.positions[i].arr;
            return `translate(${pos})`;
          })
          .style('text-anchor', function(d) {
            const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2
            return (midangle < Math.PI ? 'start' : 'end')
          })
    },
  },

  mounted() {
    this.generateGraph()
  }
}
</script>

<style scoped lang="scss">
.stat {
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  overflow: hidden;
  margin: 0 10px;
  width: 100%;
  &_small {
    max-height: 120px;
    border-radius: 0;
  }

  &__head {
    display: flex;
    align-items: center;
    height: 33px;

    &_small {
      height: 6px;
    }
  }

  &__count {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #3B3F48;
    margin-left: 18px;
    margin-right: 24px;

    width: 48px;
    text-align: right;

  }

  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #3B3F48;
    letter-spacing: 0;
  }

  &__content {
    padding: 0px 0 16px;
    background-color: #fff;
    margin-top: 0px;
  }

  &__graph {
    height: 160px;
    text-align: center;
    position: relative;
    top: -5px;
  }

  &__item {
    display: flex;
    max-width: 300px;
    align-items: center;
    margin-bottom: 8px;
  }

  &__counter {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    margin-right: 4px;
    color: #3B3F48;
    text-align: right;
    width: 48px;
    letter-spacing: 0;

  }
  &__statistic {
    display: flex;
    margin-left: 8px;

    &_sm{
      margin-left: 0;
    }
  }

  &__name {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #3B3F48;
    margin-left: 8px;

    //white-space: nowrap;
  }

  &__info {
    padding-top: 12px;
    margin-left: 18px;

    &_sm{
      padding-top: 18px;
    }
  }

  &__circe {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: relative;
    top: 3px;
  }

  &__select {
    padding-top: 35px;
    max-width: 170px;
    margin: 0 auto;

    select {
      width: 100%;
    }
  }
}



</style>