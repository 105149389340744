<template>
  <div class="list" ref="list" @scroll="scrollList">
    <div class="list__header" v-if="!search" :style="{top: top + 'px'}" @click="clearSchool" :class="!$route.query.school ? 'active' : ''">Все учреждения</div>
    <div class="list__header" v-else :style="{top: top + 'px'}">Поиск: {{search}}</div>
    <ul class="list__list">
      <li v-for="(school,index) in getSchool" :key="index" @click="changeSchool(school)" :class="getActiveSchool === school.accountId ? 'active' : ''">{{school.name}}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SidebarList",
  props: ['search', 'school'],
  data() {
    return {
      top: 0
    }
  },
  methods: {
    changeSchool(school) {
      if (this.$route.query.school != school.accountId) {
        this.$router.replace({name: 'Home', query: {school: school.accountId}})
      }
    },
    scrollList() {
      this.top = this.$refs.list.scrollTop
    },
    clearSchool() {
      this.$router.replace({query: {school: undefined}})
    }
  },
  computed: {
    getSchool() {
      if (!this.search) {
        return this.school
      }
      else {
        return this.school.filter(item => {
          return item.name.indexOf(this.search) !== -1
        })
      }
    },
    getActiveSchool() {
      return this.$route.query.school ? parseInt(this.$route.query.school) : null
    }
  }
}
</script>

<style scoped lang="scss">
  .list {
    height: calc(100% - 60px);
    overflow: auto;
    position: relative;
    padding-top: 36px;
    &__header {
      padding: 0 10px;
      height: 36px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #3B3F48;
      cursor: pointer;
      background-color: #fff;
      &.active {
        background: #F8F8F8;
      }
    }
    &__list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        cursor: pointer;
        padding: 5px 10px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #3B3F48;
        border-bottom: 1px solid rgba(#000, .1);
        height: 45px;
        display: flex;
        align-items: center;
        overflow: hidden;
        &:hover {
          background: darken(#F8F8F8, 5%);
        }
        &.active {
          background: #F8F8F8;
        }
      }
    }
  }
</style>