import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VModal from 'vue-js-modal'


import 'normalize.css'
import 'roboto-fontface'
import './assets/styles/styles.scss'

import baseClient from "./plugins/baseClient"

Vue.config.productionTip = false

Vue.prototype.$axios = baseClient.getHttpClient()
Vue.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: false } })


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
