import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/auth',
    name: 'Auth',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth.vue')
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/Users')
  },
  {
    path: '/managers',
    name: 'Managers',
    component: () => import('../views/Managers/Managers'),
    children:[
      {
        path: '',
        name: 'ManagersList',
        component: () => import('../views/Managers/ManagersList')
      },
      {
        path: 'create',
        name: 'ManagersCreate',
        component: () => import('../views/Managers/ManagersAdd')
      },
      {
        path: ':id/edit',
        name: 'ManagersEdit',
        component: () => import('../views/Managers/ManagersEdit')
      },
      {
        path: ':id/statistic',
        name: 'ManagersStatistic',
        component: () => import('../views/Managers/ManagerStatistic')
      },
    ]
  },
  {
    path: '/users/:id',
    name: 'UserItem',
    component: () => import('../views/UserItem')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
