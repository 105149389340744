<template>
  <div class="graph">
    <div class="graph-content" ref="graph"></div>
  </div>
</template>

<script>
import moment from "moment";
import * as d3 from "d3";
import _ from 'lodash'

export default {
  name: "Graph3Dv2",
  props: ['graph', 'period', 'head', 'date','selected'],
  data() {
    return {
      width: 0,
      activeFormat: '',
      minDate: '',
      maxDate: '',
      height: 0,
      margin: {
        top: 10,
        right: 30,
        bottom: 40,
        left: 50
      },
      active: null
    }
  },
  computed: {
    getTicksCount() {
      if (this.period) {
        if (this.period === 'day') {
          return 24
        } else if (this.period === 'month') {
          const date = moment(this.date)
          return date.daysInMonth()
        } else {
          return 20
        }
      }
      return 0
    },
    getFormatToInfo() {
      if (this.period === 'day') {
        return 'HH:mm'
      } else if (this.period === 'month') {
        return 'DD.MM'
      } else {
        return 'MM.YYYY'
      }
    },
    getFormatToTooltip() {
      if (this.period === 'day') {
        return 'DD-MM-YYYY HH:mm'
      } else if (this.period === 'month') {
        return 'DD-MM-YYYY HH:mm'
      } else {
        return 'DD-MM-YYYY HH:mm'
      }
    },
    getDataGraph() {
      if (this.graph.length) {
        if (this.period !== 'all') {
          if (this.x) {
            let arr = []
            this.x.ticks(this.getTicksCount).map(date => {
              const find = _.find(this.graph, val => {
                return moment(val.dt, this.activeFormat).isSame(date)
              })
              if (find) {
                arr.push(find)
              } else {
                let val = {}
                val.dt = date
                this.head.map((item) => {
                  val[item.val] = 0
                })
                arr.push(val)
              }
            })
            return arr
          }
        }
        return this.graph
      }
      return []
    },
    getMinXPosition() {
      let date
      if (this.period === 'day') {
        date = moment(this.date, 'DD-MM-YYYY')
        date.hours(0)
      } else if (this.period === 'month') {
        date = moment(this.date, 'MMMM YYYY').date(1)
      } else {
        if (this.graph.length > 1) {
          date = moment(this.graph[0].dt, 'YYYY-MM')
        } else {
          date = moment(2018, 'YYYY')
        }
      }
      return date.format(this.activeFormat)
    },
    getMaxPosition() {
      let date
      if (this.period === 'day') {
        date = moment(this.date, 'DD-MM-YYYY')
        date.hours(23)
      } else if (this.period === 'month') {
        date = moment(this.date, 'MMMM YYYY')
        date.date(date.daysInMonth())
      } else {
        if (this.graph.length > 1) {
          date = moment(this.graph[this.graph.length - 1].dt, 'YYYY-MM')
        } else {
          date = moment()
        }
      }
      return date.format(this.activeFormat)
    },
  },
  methods: {
    mouseMove(e) {
      const point = d3.pointer(e)
      this.tooltip.style('opacity', 1)
      this.tooltip
          .style('display', 'block')
          .style("left", (point[0] + (point[0] > 600 ? -80 : 60)) + "px") // It is important to put the +90: other wise the tooltip is exactly where the point is an it creates a weird effect
          .style("top", (point[1]) + "px")
          .style("max-width", '150px')
    },
    generateGraph() {
      this.el = this.el = d3.select(this.$refs.graph)
          .append("svg")
          .attr("width", this.width + this.margin.left + this.margin.right)
          .attr("height", this.height + this.margin.top + this.margin.bottom)
          .append("g")
          .attr("transform",
              "translate(" + this.margin.left + "," + this.margin.top + ")")
    },
    generateGraphX() {
      this.x = d3.scaleTime().domain([moment(this.getMinXPosition, this.activeFormat), moment(this.getMaxPosition, this.activeFormat)]).range([0, this.width])
      this.el.append('g')
          .attr("transform", "translate(0," + this.height + ")")
          .call(
              d3.axisBottom(this.x)
                  .ticks(this.getTicksCount)
                  .tickFormat((d) => {
                    return moment(d).format(this.getFormatToInfo);
                  })
          )
          .selectAll('text')
          .attr("transform", "translate(-2,2)rotate(-45)")
          .style("text-anchor", "end")
    },
    generateGraphY() {

      this.y = d3.scaleLinear()
          .domain([0, d3.max(this.graph, d => {
            let maxVal = Number(d.payCount || d.inputCount || d.count)
            this.maxY = maxVal + (maxVal / 100 * 40)
            this.maxY = this.maxY >= 10 ? this.maxY : 10
            return this.maxY
          })])
          .range([this.height, 0])
      this.el.append("g")
          .call(d3.axisLeft(this.y).ticks(this.maxY > 10 ? 10 : this.maxY))
    },
    generateData() {
      this.head.map((val, index) => {
        this.drawLine(val, index)
      })
    },
    drawLine(info) {
      this.el.append('path')
          .datum(this.getDataGraph)
          .attr('class', 'line')
          .attr('data-line', info.val)
          .attr("fill", "none")
          .attr("stroke", info.color)
          .attr('opacity', info.val === this.selected ? 1 : 0)
          .attr("stroke-width", 1.5)
          .attr('d', d3.line()
              .curve(d3.curveMonotoneX)
              .x(d => {
                return this.x(new Date(d.dt))
              })
              .y(d => {
                return this.y(d[info.val])
              })
          )
      const vm = this
      this.el.selectAll('tick')
          .data(this.getDataGraph.filter(d => d[info.val] > 0))
          .enter()
          .append("circle")
          .attr('class', 'circle')
          .attr('data-line', info.val)
          .attr("fill", info.color)
          .attr('display', info.val === this.selected ? 'block' : 'none')
          .attr("stroke", "none")
          .attr("cx", (d) => {
            return this.x(new Date(d.dt))
          })
          .attr("cy", (d) => {
            return d[info.val] > 0 ? this.y(d[info.val]) : this.y(d[info.val])
          })
          .attr("r", 5)
          .html((d) => {
            let html = ''
            html += `<p style="text-align:center;">${moment(d.dt).format('DD-MM-YYYY')}<br/>${moment(d.dt).format('HH-mm')}</p>`
            html += `<p style="text-align:center;font-weight:bold;font-size: 20px;line-height: 1;">${d[info.val]}</p>`
            return html;
          })
          .on('mouseover', function () {
            vm.tooltip.html(this.innerHTML)
          })
          .on('mousemove', this.mouseMove)
          .on("mouseleave", function () {
            vm.tooltip.style('opacity', 0)
            vm.tooltip.style('display', 'none')
          })
    },
    changeActiveFormat() {
      if (this.period === 'day') {
        this.activeFormat = 'YYYY-MM-DD HH:mm'
      } else if (this.period === 'month') {
        this.activeFormat = 'YYYY-MM-DD'
      } else {
        this.activeFormat = 'YYYY-MM'
      }
    }
  },
  async mounted() {
    this.width = this.$refs.graph.offsetWidth - this.margin.left - this.margin.right
    this.height = (this.width * 0.5625) - this.margin.top
    await this.changeActiveFormat()
    await this.generateGraph()
    await this.generateGraphX()
    await this.generateGraphY()
    await this.generateData()
    this.tooltip = d3.select(this.$refs.graph)
        .append("div")
        .style("opacity", 0)
        .attr("class", "tooltip")
        .style("background-color", "#F1F1F1")
        .style("border", "solid")
        .style("border-width", "0px")
        .style("border-radius", "5px")
        .style("padding", "0px 10px")
        .style('display', 'none')
  },
  watch: {

    selected() {
      if (this.el) {
        const active = this.selected
        this.el.selectAll('.line').each(function () {
          const attr = this.getAttribute('data-line')
          if (active === attr) {
            this.style.opacity = 1
          } else {
            this.style.opacity = 0
          }
        })
        this.el.selectAll('.circle').each(function () {
          const attr = this.getAttribute('data-line')
          if (active === attr) {
            this.style.display = 'block'
          } else {
            this.style.display = 'none'
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.graph {
  padding-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
  position: relative;

  &-content {
    position: relative;

    .tooltip {
      position: absolute;
      max-width: 200px;
    }
  }

  &__head {
    position: absolute;
    right: 80px;
    top: 40px;
    display: flex;
    justify-content: space-between;
    z-index: 2;
  }

  &__item {
    margin-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  &__circle {
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 200em;
    cursor: pointer;
  }

  &__text {
    margin-left: 5px;
    font-size: 12px;
  }
}
</style>