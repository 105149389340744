<template>
  <div class="navbar">
    <div class="navbar__item navbar__logo">
      <router-link to="/">
        SOVA
      </router-link>
    </div>
    <div class="navbar__item navbar__list">
      <p
          class="navbar__text"
          :class="{'navbar__text_selected' : $route.name === 'Home'}"
          @click="$router.push('/')">Аналитика</p>
      <p
          class="navbar__text"
          :class="{'navbar__text_selected' : $route.name === 'Users'}"
          @click="$router.push({name:'Users'})">Пользователи</p>
      <p
          class="navbar__text"
          :class="{'navbar__text_selected' : $route.name === 'ManagersList'}"
          @click="$router.push({name:'ManagersList'})">Менеджеры продаж</p>
    </div>
    <div class="navbar__item">
      <button
          class="navbar__exit"
          @click="exit"
      >Выход <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 0C1.11929 0 3.72529e-08 1.11929 3.72529e-08 2.5V3.33333C3.72529e-08 3.79357 0.373096 4.16667 0.833333 4.16667C1.29357 4.16667 1.66667 3.79357 1.66667 3.33333V2.5C1.66667 2.03976 2.03976 1.66667 2.5 1.66667H9.16667C9.6269 1.66667 10 2.03976 10 2.5V14.1667C10 14.6269 9.6269 15 9.16667 15H2.5C2.03976 15 1.66667 14.6269 1.66667 14.1667V13.3333C1.66667 12.8731 1.29357 12.5 0.833333 12.5C0.373096 12.5 3.72529e-08 12.8731 3.72529e-08 13.3333V14.1667C3.72529e-08 15.5474 1.11929 16.6667 2.5 16.6667H9.16667C10.5474 16.6667 11.6667 15.5474 11.6667 14.1667V2.5C11.6667 1.11929 10.5474 0 9.16667 0H2.5Z" fill="white"/>
        <path d="M4.75592 5.58926C5.08136 5.26382 5.08136 4.73618 4.75592 4.41074C4.43049 4.08531 3.90285 4.08531 3.57741 4.41074L0.244078 7.74408C-0.0813592 8.06952 -0.0813592 8.59715 0.244078 8.92259L3.57741 12.2559C3.90285 12.5814 4.43049 12.5814 4.75592 12.2559C5.08136 11.9305 5.08136 11.4028 4.75592 11.0774L2.01184 8.33333L4.75592 5.58926Z" fill="white"/>
      </svg>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navbar",
  data(){
    return {
      selected: ''
    }
  },
  methods:{
    exit(){
      localStorage.clear()
      if (this.$route.name === 'ManagerHome'){
        this.$router.push({name: 'ManagerAuth'})
      } else {
        this.$router.push({name: 'Auth'})
      }
    }
  },
  mounted() {
    this.$router.afterEach((to,from) =>{
      switch (from.name){
        case "ManagersList":
            break;
      }
    })
  }
}
</script>

<style scoped lang="scss">
  .navbar{
    width: 100%;
    height: 56px;
    background: #343A40;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    &__item{
      display: flex;
    }

    &__logo{
      color: #fff;
      margin-left: 22px;
      font-family: 'CodeNext';
      font-weight: 900;
      font-size: 18px;
      letter-spacing: 1px;
      line-height: 22px;
      a {
        color: #fff;
        text-decoration: none;
      }
    }

    &__list{

      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      min-width: 300px;
      height: 30px;
      align-items: center;
    }

    &__text{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      color: #FFFFFF;
      opacity: 0.5;
      cursor: pointer;
      margin: 0;
      margin-left: 20px;
      margin-right: 20px;
      padding-left: 20px;
      padding-right: 20px;
      height: 36px;
      white-space: nowrap;
      margin-top: 20px;

      &:first-child{
        margin-left: 0;
      }

      &:last-child{
        margin-right: 0;
      }

      &_selected{
        border-bottom: 3px solid #fff;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        opacity: 1;
      }

      &:hover{
        opacity: .75;
      }
    }

    &__exit{
      background: #6099FC;
      border-radius: 4px;
      width: 95px;
      height: 36px;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;

      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      outline: 0;
      margin-right: 22px;
      cursor: pointer;

      &:hover{
        opacity: .75;
      }

      svg{
        margin-left: 14px;
      }
    }



  }
</style>