<template>
  <div class="counter">
    <div class="counter__header">
      <div class="counter__search">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.0624 11.8375C13.1612 10.6856 13.8359 9.12542 13.8359 7.40771C13.8359 3.86263 10.9621 0.98877 7.41698 0.98877C3.8719 0.98877 0.998047 3.86263 0.998047 7.40771C0.998047 10.9528 3.8719 13.8266 7.41698 13.8266C9.24435 13.8266 10.8934 13.063 12.0624 11.8375ZM12.0624 11.8375L16.7752 16.5504" stroke="#E0E0E0" stroke-width="1.36259" stroke-linecap="round"/>
        </svg>
        <input type="text" v-model="search" class="counter__input" placeholder="Поиск по учреждениям">
      </div>
      <p class="counter__header__text">ВСЕ УЧРЕЖДЕНИЯ 100%</p>
    </div>
    <div class="counter__table" border="0" cellspacing="0">
      <div class="counter__head">
        <div class="counter__item">
          {{!isSchool ? 'Учреждение' : 'Класс'}}
        </div>
        <div class="counter__item">Аккаунты</div>
        <div class="counter__item">Клиенты</div>
        <div class="counter__item counter__item_small">Клиенты<br/></div>
        <div class="counter__item counter__item_small">Аккаунты<br/></div>
      </div>
      <div class="counter__block">
        <counter-item v-for="item in getCounters" :key="item.accountId" :counter="item" :all="getAllParents"></counter-item>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import CounterItem from "./CounterItem";
export default {
  name: "Counter",
  components: {CounterItem},
  props: ['counter'],
  data() {
    return {
      search: null
    }
  },
  computed: {
      getCounters() {
        if (this.search) {
          return _.filter(this.counter, item => {
            return item.name.indexOf(this.search) !== -1
          })
        }
        else {
          return this.counter
        }
      },
      isSchool() {
        return this.$route.query.school
      },
      getAllParents() {
        let count = {
          parents: 0,
          account: 0
        }
        this.counter.map(item => {
          count.parents += item.statistic.parentsCount || 0
          count.account += item.statistic.unavailableKeys || 0
        })
        return count
      }
  }
}
</script>

<style scoped lang="scss">
  .counter {
    height: 692px;
    overflow: hidden;


    &__header{
      margin-left: 24px;
      display: flex;
      justify-content: space-between;

      &__text{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        color: #3B3F48;
        background: #F9F9F9;
        border-radius: 30px 30px 0px 0px;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 31px;
        padding-right: 20px;
        margin: 0;
      }
    }

    &__search {
      display: flex;
      background: #FAFAFA;
      border-radius: 4px;
      width: 243px;
      align-items: center;
      height: 36px;

      svg{
        margin-left: 10px;
      }
    }
    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;
      .counter__item {
        padding: 0px 20px;
      }
    }
    &__item {
      width: 25%;
      font-size: 14px;
      font-weight: 500;
      &_small {
        width: 12.5%;
        background: rgba(0, 0, 0, 0.0292832);
        font-size: 14px;
        text-align: left;
        padding-left: 5px !important;
      }
    }
    &__input {
      background: transparent;
      border: 0;
      outline: 0;
      color: black;
      margin-left: 10px;
      width: 200px;

      opacity: .5;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;

      ::placeholder{
        color: #CCCCCC;
      }
    }
    &__block {
      overflow: scroll;
      height: 640px;

      &::-webkit-scrollbar {
        display: none;
      }
    }
    &__table {
      width: 100%;
      tbody {
        overflow: auto;
      }
      th {
        padding-top: 30px;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;

        color: #3B3F48;
        &.counter__item_small {
          font-size: 12px;
        }
      }
      th, td {
        padding-bottom: 16px;
        border-bottom:  1px solid rgba(0, 0, 0, 0.044799);
      }
    }

  }
</style>